import gql from "graphql-tag";
import {
  AttributeFilter,
  ChosenAttributesInput,
  PageInfo,
  ProductTaxonomyFilterInput,
} from "../types/generalTypes";
import { ProductProps } from "../types/productTypes";

export interface GetProductsVariables {
  limit: number;
  before?: string;
  after?: string;
  category?: string;
  field?: string;
  order?: string;
  taxonomyFilter?: ProductTaxonomyFilterInput[];
  minPrice?: number;
  maxPrice?: number;
  onSale?: boolean;
  day?: number;
  month?: number;
  year?: number;
  ids?: number[];
}

export interface GetProductsResponse {
  products: {
    nodes: ProductProps[];
    pageInfo?: PageInfo;
  };
}

export const getProducts = gql`
  query getProducts(
    $last: Int
    $limit: Int
    $after: String
    $before: String
    $category: String
    $field: ProductsOrderByEnum!
    $order: OrderEnum
    $taxonomyFilter: [ProductTaxonomyFilterInput]
    $minPrice: Float
    $maxPrice: Float
    $onSale: Boolean
    $day: Int
    $month: Int
    $year: Int
    $ids: [Int]
  ) {
    products(
      last: $last
      first: $limit
      after: $after
      before: $before
      where: {
        category: $category
        orderby: { field: $field, order: $order }
        taxonomyFilter: { or: $taxonomyFilter }
        minPrice: $minPrice
        maxPrice: $maxPrice
        onSale: $onSale
        dateQuery: { after: { day: $day, month: $month, year: $year } }
        include: $ids,
        stockStatus: IN_STOCK
      }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        type
        description

        ... on VariableProduct {
          id
          name
          variations(first: 100, where : {stockStatus: IN_STOCK}) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          stockStatus
          stockQuantity
          onSale
        }
        image {
          id
          slug
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
        productBrands {
          nodes {
            name
            slug
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export interface ShopProductCategory {
  id: string;
  link: string;
  slug: string;
  name: string;
  children: {
    nodes: ShopProductCategory[];
  };
}

export interface GetShopProductCategoriesVariables {
  customTaxonomy?: ChosenAttributesInput[];
}

export interface getMenuVariables {}

export interface GetShopProductCategoriesResponse {
  productCategories: {
    nodes: ShopProductCategory[];
  };
}

export interface getMenuResponse {
  products: {
    nodes: [
      {
        productCategories: {
          nodes: ShopProductCategory[];
        };
      }
    ];
  };
}

export const getProductCategories = gql`
  query getProductCategories($customTaxonomy: [ChosenAttributesInput]) {
    productCategories(
      first: 100
      where: {
        hideEmpty: true
        exclude: "15"
        customTaxonomy: $customTaxonomy
        parent: 0
      }
    ) {
      nodes {
        id
        link
        name
        slug
        children(first: 100, where: { hideEmpty: true, customTaxonomy: $customTaxonomy }) {
          nodes {
            id
            name
            link
            slug
            children(
              where: { hideEmpty: true, customTaxonomy: $customTaxonomy }
            ) {
              nodes {
                id
                name
                link
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const getMenu = gql`
  query paGjinias {
    edges {
      node {
        id
        name
      }
    }
  }
`;

export const GET_MENU = gql`
  query getMenu {
    paGjinias(where: { orderby: TERM_ORDER }) {
      nodes {
        id
        name
        slug
        products {
          nodes {
            productCategories(
              first: 100
              where: { hideEmpty: true, exclude: "15", parent: 0 }
            ) {
              nodes {
                id
                link
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MENU_CHILDS = gql`
  query getMenuChilds($id: ID!) {
    paGjinia(id: $id) {
      name
      id
      slug
      products {
        nodes {
          productCategories(
            first: 100
            where: { hideEmpty: true, exclude: "15", parent: 0 }
          ) {
            nodes {
              id
              link
              name
              slug
              children(where: { hideEmpty: true }) {
                nodes {
                  id
                  name
                  link
                  slug
                  children(where: { hideEmpty: true }) {
                    nodes {
                      id
                      name
                      link
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface GetAttributeFiltersVariables {
  chosenAttributes: ChosenAttributesInput[];
}

export interface GetAttributeFiltersResponse {
  attributeFilters: AttributeFilter[];
}

export const getAttributeFilters = gql`
  query getAttributeFilters($chosenAttributes: [ChosenAttributesInput]) {
    attributeFilters(chosenAttributes: $chosenAttributes) {
      id
      name
      label
      slug
      terms {
        id
        count
        name
        slug
      }
    }
  }
`;

export interface GetCategoryBySlugVariables {
  slug: string;
}

export interface GetCategoryBySlugResponse {
  productCategory: {
    image: {
      mediaItemUrl: string
    }
    categoryBannerImage: {
      bannerImage: {
        mediaItemUrl: string
      }
    }
  }
}

export const getCategoryBySlug = gql`
  query getCategoryBySlug($slug: ID!) {
    productCategory(id: $slug, idType: SLUG) {
      image {
        mediaItemUrl
      }
      categoryBannerImage {
        bannerImage {
          mediaItemUrl
        }
      }
    }
  }
`;

export const NEW_PRODUCTS = gql`
  query newProducts {
    themeOptions {
      themeOptions {
        newProducts
      }
    }
  }
`;
