import gql from "graphql-tag";
import { ProductProps } from "../types/productTypes";
import { PageInfo, ProductTaxonomyFilterInput } from "../types/generalTypes";

export interface GetProductsVariables {
  limit: number;
  before?: string;
  after?: string;
  category?: string;
  field?: string;
  order?: string;
  taxonomyFilter?: ProductTaxonomyFilterInput[];
  minPrice?: number;
  maxPrice?: number;
  onSale?: boolean;
  day?: number;
  month?: number;
  year?: number;
  ids?: number[];
  brand?: string;
}

export interface GetProductsResponse {
  products: {
    nodes: ProductProps[];
    pageInfo?: PageInfo;
  };
}

export const getProducts = gql`
  query getProducts(
    $last: Int
    $limit: Int
    $after: String
    $before: String
    $category: String
    $field: ProductsOrderByEnum!
    $order: OrderEnum
    $taxonomyFilter: [ProductTaxonomyFilterInput]
    $minPrice: Float
    $maxPrice: Float
    $onSale: Boolean
    $day: Int
    $month: Int
    $year: Int
    $ids: [Int]
    $brand: String
  ) {
    products(
      last: $last
      first: $limit
      after: $after
      before: $before
      where: {
        category: $category
        orderby: { field: $field, order: $order }
        taxonomyFilter: { or: $taxonomyFilter }
        minPrice: $minPrice
        maxPrice: $maxPrice
        onSale: $onSale
        dateQuery: { after: { day: $day, month: $month, year: $year } }
        include: $ids
        productBrandSlug: $brand,
        stockStatus: IN_STOCK
      }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        type
        description

        ... on VariableProduct {
          id
          name
          variations(first: 100, where: {stockStatus: IN_STOCK}) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          stockStatus
          stockQuantity
          onSale
        }
        image {
          id
          slug
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
        productBrands {
          nodes {
            name
            slug
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
