import gql from "graphql-tag";

export const getNewInProducts = gql`
  query getNewInProducts {
    products(where: { stockStatus: IN_STOCK, orderby: { field: DATE, order: DESC } }, first: 9) {
      nodes {
        id
        databaseId
        slug
        type
        ... on VariableProduct {
          id
          name
          variations(first: 100, where: {stockStatus: IN_STOCK}) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          stockQuantity
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
    }
  }
`;

export const getProductSale = gql`
  query ProductSale {
    generalOptions {
      generalOptions {
        dailyProduct {
          ... on SimpleProduct {
            id
            databaseId
            name
            stockQuantity
            stockStatus
            description
            image {
              id
              mediaItemUrl
              sourceUrl(size: _300X210)
            }
          }
        }
      }
    }
  }
`;

export const getMostWatchedProducts = gql`
  query getMostWatchedProducts($category: String) {
    products(where: { category: $category, stockStatus: IN_STOCK }, first: 9) {
      nodes {
        id
        slug
        databaseId
        type

        ... on VariableProduct {
          id
          name
          variations(first: 100, where: {stockStatus: IN_STOCK}) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
          stockQuantity
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
    }
  }
`;

export const getNotifications = gql`
  query getNotifications($userId: String) {
    notifications(where: { userId: $userId }) {
      nodes {
        id
        title
        slug
        link
        content
        notificationData {
          orderId
          thumbnailUrl
          read
          customLink
        }
      }
    }
  }
`;

export const markAsReadNotification = gql`
  mutation markAsReadNotificationMutation($userId: String!) {
    markNotificationAsRead(input: { userId: $userId }) {
      clientMutationId
      message
    }
  }
`;

export const GET_TOP_SALES_PRODUCTS = gql`
  query getTopSalesProducts {
    products(where: { stockStatus: IN_STOCK, orderby: { field: TOTAL_SALES } }, first: 9) {
      nodes {
        id
        slug
        databaseId
        ... on VariableProduct {
          id
          name
          variations(first: 100, where: { stockStatus: IN_STOCK }) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          id
          name
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
    }
  }
`;

export const GET_HOT_SALES_PRODUCTS = gql`
  query getHotSalesProducts {
    hotSales {
      id
      name
      price
      sale_price
      slug
      stockQuantity
      type
      thumbnailUrl
      uri
    }
  }
`;

//TODO: update this
export const getHomeBlogPosts = gql`
  query getBestSellers {
    products(where: { onSale: true, stockStatus: IN_STOCK }, first: 3) {
      nodes {
        id
        slug
        databaseId
        ... on VariableProduct {
          id
          name
          price(format: FORMATTED)
        }
        ... on SimpleProduct {
          id
          name
          price(format: FORMATTED)
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
    }
  }
`;

export const getCourses = gql`
  query getCourses($courseCategoryId: String, $productBrandId: String) {
    courses(
      first: 100
      where: {
        courseCategoryId: $courseCategoryId
        productBrandId: $productBrandId
      }
    ) {
      nodes {
        id
        databaseId
        content
        slug
        title
        featuredImage {
          node {
            mediaItemUrl
            slug
            id
          }
        }
        courseDoc {
          document {
            link
            id
          }
        }
        productBrands {
          nodes {
            name
            id
          }
        }
        courseVideo {
          video
        }
      }
    }
  }
`;

export const getCoursesCategories = gql`
  query getCoursesCategories {
    courseCategories {
      nodes {
        id
        name
        slug
        databaseId
      }
    }
  }
`;

export const getCourseById = gql`
  query getCourseById($id: ID!) {
    course(id: $id) {
      id
      databaseId
      content
      slug
      title
      featuredImage {
        node {
          id
          mediaItemUrl
          slug
        }
      }
      courseDoc {
        document {
          link
          id
        }
      }
      productBrands {
        nodes {
          name
          id
        }
      }
      courseVideo {
        video
      }
    }
  }
`;
